<template>
  <b-form ref="form" @submit.stop.prevent="">
    <b-form-group label-for="reisekuerzel" :invalid-feedback="validateState('reisekuerzel', true)">
      <slot name="label">
        <label v-t="'REISEN.COMMON.REISEKUERZEL'"></label>
        &nbsp;
        <b-icon-question-circle-fill
          v-b-popover.hover.top="$t('REISEN.POPOVERTEXT.Widget1')"
        ></b-icon-question-circle-fill>
      </slot>
      <b-form-input
        id="reisekuerzel"
        disabled
        v-model="$v.form.reisekuerzel.$model"
        :formatter="v => v.toUpperCase()"
        :state="validateState('reisekuerzel')"
        type="text"
        trim
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Reisetitel" label-for="titel">
      <b-form-input
        id="titel"
        type="text"
        v-model="$v.form.titel.$model"
        :disabled="!isUserAllowedToEdit"
        :state="$v.form.titel.$model?.length > 0"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      :invalid-feedback="validateState('country', true)"
      :label="$t('REISEN.LANDEINGEBEN')"
      label-for="country"
    >
      <b-form-select
        id="country"
        v-model="$v.form.country.$model"
        :disabled="!isUserAllowedToEdit"
        :state="validateState('country')"
        :options="countriesOptions"
        text-field="text"
        value-field="value"
      ></b-form-select>
    </b-form-group>
    <b-form-group
      :invalid-feedback="validateState('region', true)"
      :label="$t('REISEN.COMMON.REGION')"
      label-for="region"
    >
      <b-form-select
        id="region"
        v-model="$v.form.region.$model"
        :disabled="!isUserAllowedToEdit"
        :state="validateState('region')"
        :options="regionOptions"
        text-field="text"
        value-field="value"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      :invalid-feedback="validateState('prioritaet', true)"
      :label="$t('REISEN.PRIORITAETEINGEBEN')"
      label-for="prioritaet"
    >
      <b-form-select
        id="prioritaet"
        :disabled="!isUserAllowedToEdit"
        v-model="$v.form.prioritaet.$model"
        :state="validateState('prioritaet')"
        :options="prioritaetOptions"
        text-field="value"
      ></b-form-select>
    </b-form-group>
    <b-form-group
      label="Regionsmanager"
      label-for="regionsmanager"
      :invalid-feedback="validateState('regionsmanager', true)"
    >
      <b-form-select
        id="regionsmanager"
        v-model="$v.form.regionsmanager.$model"
        :disabled="!isUserAllowedToEdit"
        :state="validateState('regionsmanager')"
        :options="regionsmanagerOptions"
      ></b-form-select>
    </b-form-group>
    <b-form-group class="mb-3">
      <b-form-checkbox
        id="automatischeAufgabeMtzErreicht"
        switch
        v-model="$v.form.automatischeAufgabeMtzErreicht.$model"
        :disabled="!isUserAllowedToEdit"
      >
        Automatische Aufgabe wird bei erreichter MTZ erstellt
      </b-form-checkbox>
    </b-form-group>
    <b-form-group style="margin-bottom: 16px">
      <b-form-checkbox
        id="automatischeAufgabeTktl"
        switch
        v-model="$v.form.automatischeAufgabeTktl.$model"
        :disabled="!isUserAllowedToEdit"
      >
        Automatische Aufgabe “Kurzes TKTL freischalten/deaktivieren" wird erstellt
      </b-form-checkbox>
    </b-form-group>
    <b-button v-allowedRoles="[Role.ADMIN, Role.PM, Role.DOM]" variant="primary" class="mr-3" @click="save">
      Speichern
    </b-button>
    <b-button v-allowedRoles="[Role.ADMIN, Role.PM, Role.DOM]" variant="secondary" @click="abort">
      Zurücksetzen
    </b-button>
  </b-form>
</template>

<script>
import { UPDATE_REISE } from '@/core/produkte/reisen/stores/reisen.module.js';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { mapGetters, mapState } from 'vuex';

import configCountries from '@/core/produkte/reisen/countries.config.json';
import configRegions from '@/core/produkte/reisen/regions.config.json';
import configPirority from '@/core/produkte/reisen/priority.config.json';

export default {
  mixins: [validationMixin],
  name: 'ReisenStandardDataEditForm',
  props: {
    reise: Object,
  },
  data() {
    return {
      form: {
        reisekuerzel: null,
        country: null,
        region: null,
        prioritaet: null,
        titel: '',
        regionsmanager: null,
        automatischeAufgabeMtzErreicht: null,
        automatischeAufgabeTktl: null,
      },
      error: {},
    };
  },
  watch: {
    reise: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.setFormToReise(newVal);
        }
      },
    },
  },
  computed: {
    ...mapGetters(['currentUserId', 'parsedRoles']),
    ...mapState({ users: state => state.users['users'] }),
    isUserAllowedToEdit() {
      return (
        this.parsedRoles.includes('Admin') ||
        this.parsedRoles.includes('Pm') ||
        this.parsedRoles.includes('Dom')
      );
    },
    regionOptions() {
      return Object.entries(configRegions)
        .reduce((acc, cur) => {
          acc.push({ value: cur[1].value, text: this.$t('REISEN.REGIONS.' + cur[1].value) });
          return acc;
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    regionsmanagerOptions() {
      return this.users
        .filter(user => user.department && user.department === 'Flug Yield Management')
        .map(user => ({ value: user.id, text: user.givenName + ' ' + user.surname }));
    },
    countriesOptions() {
      return Object.entries(configCountries)
        .reduce((acc, cur) => {
          acc.push({ value: cur[1].value, text: this.$t('REISEN.COUNTRIES.' + cur[1].value) });
          return acc;
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    prioritaetOptions() {
      return configPirority || [];
    },
  },
  validations: {
    form: {
      reisekuerzel: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      country: {
        required,
      },
      region: {
        required,
      },
      prioritaet: {
        required,
      },
      titel: { required },
      regionsmanager: { required },
      automatischeAufgabeMtzErreicht: { required },
      automatischeAufgabeTktl: { required },
    },
  },
  methods: {
    setFormToReise(reise) {
      this.form.reisekuerzel = reise.reisekuerzel;
      this.form.country = reise.country;
      this.form.region = reise.region;
      this.form.prioritaet = this.prioritaetOptions[this.reise.prioritaet].value;
      this.form.titel = reise.titel;
      this.form.regionsmanager = reise.regionsmanager;
      this.form.automatischeAufgabeMtzErreicht = reise.automatischeAufgabeMtzErreicht;
      this.form.automatischeAufgabeTktl = reise.automatischeAufgabeTktl;
    },
    validateState(name, mode = false) {
      if (!mode) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
      if (this.$v.form[name].required != null && !this.$v.form[name].required) {
        return this.$t('FORMS.REQUIREDFIELD');
      }

      if (this.$v.form[name].alpha != null && !this.$v.form[name].alpha) {
        return this.$t('FORMS.BUCHSTABEN');
      }

      if (this.$v.form[name].minLength != null && !this.$v.form[name].minLength) {
        return this.$t('FORMS.BETWEENCHARACTERS', { length: this.$v.form[name].$params.minLength.min });
      }

      if (this.$v.form[name].maxLength != null && !this.$v.form[name].maxLength) {
        return this.$t('FORMS.BETWEENCHARACTERS', { length: this.$v.form[name].$params.minLength.min });
      }

      if (this.$v.form[name].between != null && !this.$v.form[name].between) {
        return this.$t('FORMS.MAXIMUMCHARACTERS', {
          min: this.$v.form[name].$params.between.min,
          max: this.$v.form[name].$params.between.max,
        });
      }
    },
    async save() {
      const reise = {
        ...this.form,
        id: this.reise.id,
        prioritaet: Object.entries(this.prioritaetOptions).find(
          prio => prio[1].value == this.form.prioritaet
        )[0],
      };
      await this.$store
        .dispatch(UPDATE_REISE, {
          reise,
        })
        .then(resp => {
          if (resp.status === 200) {
            this.toast('Reise erfolgreich bearbeitet.');
          }
        });
    },
    abort() {
      this.setFormToReise();
    },
  },
};
</script>
