<template>
  <div class="card card-custom gutter-b disabled">
    <div class="card-body pt-3 pb-0 pl-3">
      <b-row>
        <b-col class="m-5">
          <h4><p>Allgemeine Daten</p></h4>
          <div class="border mb-3 p-5">
            <ReiseDefaultDataEditForm :reise="reise"></ReiseDefaultDataEditForm>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { mapState } from 'vuex';
import ReiseDefaultDataEditForm from '@/components/produkte/reisen/reise-standard-data-edit-form.vue';

export default {
  name: 'reisen-edit-view',
  props: {
    title: String,
    reise: Object,
  },
  components: { ReiseDefaultDataEditForm },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      roles: state => state.auth.account.idTokenClaims.roles,
    }),
  },
  methods: {},
};
</script>
